export default {
    methods: {
        displayToast(error, successMessage = 'Success', errorMessage) {
            if (error) {
                errorMessage = errorMessage || error?.response?.data.message
                this.displayErrorFormSubmit(errorMessage)
            } else {
                this.displaySuccessFormSubmit(successMessage)
            }
        },
        displayErrorFormSubmit(message) {
            this.$root.$bvToast.toast(message, {
                title: 'Error',
                variant: 'danger',
                solid: true,
                noAutoHide: false,
                autoHideDelay: 5000
            })
        },
        displaySuccessFormSubmit(message = 'Success') {
            this.$root.$bvToast.toast(message, {
                title: 'Success',
                variant: 'success',
                solid: true,
                noAutoHide: false,
                autoHideDelay: 3000
            })
        }
    }
}
