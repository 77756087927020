<template>
    <b-modal
        id="otp-modal"
        ref="otp-modal"
        class="modal-content"
        :hide-header="true"
        :hide-footer="true"
        centered
    >
        <div class="header-container">
            <b-button class="close-button" @click="hideModal"><font-awesome-icon :icon="[ 'far', 'times-circle' ]" /></b-button>
            <b-row>
                <b-col class="logo-center">
                    <b-img
                        :src="branding.primaryLogoUrl"
                        class="mx-2 pt-2"
                        alt=" "
                    />
                </b-col>
            </b-row>
            <b-row class="justify-content-center">
                <b-col class="avatar-container">
                    <avatar
                        :avatar="player.avatar || null"
                        :default-avatar="branding.defaultAvatarUrl"
                    />
                </b-col>
            </b-row>
        </div>
        <div class="footer-container">
            <div class="title">{{ $t('otpModal.title') }}</div>
            <div
                class="form-group"
            >
                <label>{{ $t('otpModal.insertCode') }}</label>
                <div class="input-row">
                    <v-otp-input
                        id="otpInput"
                        ref="otpInput"
                        input-classes="otp-input"
                        separator=""
                        :num-inputs="6"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        @on-complete="handleOnComplete"
                    />
                </div>
            </div>
            <span
                class="otp-clear"
                title="Clear"
                @click="handleClearInput"
            >
                {{ $t('otpModal.clear') }}
            </span>
            <div class="error-container">
                <div v-show="errorMessageToShow" class="error-message">{{ errorMessageToShow }}</div>
            </div>
            <b-button
                :disabled="isSubmitDisabled"
                class="submit-button"
                @click="submit"
            >
                <span v-if="!isSubmitting">{{ $t('otpModal.submit') }}</span>
                <b-spinner v-else small />
            </b-button>
        </div>
    </b-modal>
</template>

<script>
    import emitter from '@/utils/emitter'
    import Avatar from '@/components/Avatar'
    import playerService from '@/services/player-service'
    import OtpInput from '@bachdgvn/vue-otp-input'

    export default {
        name: 'OtpModal',
        components: { Avatar, 'v-otp-input': OtpInput },
        data() {
            return {
                otpCode: null,
                errorMessageToShow: null,
                playerMobile: null,
                minutesToWait: null,
                isSubmitting: false
            }
        },
        computed: {
            player() {
                return this.$store.getters.getCurrentUser
            },
            branding() {
                return this.$store.getters.getBranding
            },
            isSubmitDisabled() {
                return this.isSubmitting || !this.otpCode
            }
        },
        created() {
            emitter.on('openOtpModal', (mobile) => {
                this.$bvModal.show('otp-modal')
                this.playerMobile = mobile
                this.sendOtp()
            })
        },
        methods: {
            sendOtp() {
                playerService.changeMobile(this.playerMobile).then(() => {
                    this.errorMessageToShow = null
                }).catch((error) => {
                    this.handleError(error)
                })
            },
            handleOnComplete(value) {
                if (value) {
                    this.otpCode = value
                    this.submit()
                }
            },
            handleClearInput() {
                this.$refs.otpInput.clearInput()
            },
            handleError(error) {
                if (error.response?.data?.errorCode === 'LAST_REQUESTED_OTP_STILL_ACTIVE') {
                    const minutes = (new Date(error.response.data.timeUntilNextRequest).getTime() - Date.now()) / 1000 / 60
                    this.minutesToWait = Math.ceil(minutes)
                    this.errorMessageToShow = this.$t('otpModal.pleaseTryAgain', { minutesToWait: this.minutesToWait })
                    setTimeout(() => {
                        this.errorMessageToShow = null
                    }, minutes * 60 * 1000)
                } else if (error.response?.data?.code === 'PLAYER_EXISTS') {
                    this.errorMessageToShow = this.$t('otpModal.playerExists')
                } else {
                    this.errorMessageToShow = this.$t('otpModal.verificationFailed')
                }
            },
            submit() {
                this.isSubmitting = true
                playerService.verifyMobile(this.otpCode).then(() => {
                    emitter.emit('otp-check-response', true)
                    this.$refs['otp-modal'].hide()
                    this.otpCode = null
                }).catch(() => {
                    this.errorMessageToShow = this.$t('otpModal.verificationFailed')
                }).finally(() => {
                    this.isSubmitting = false
                })
            },
            hideModal() {
                this.$refs['otp-modal'].hide()
                this.otpCode = null
                this.errorMessageToShow = null
            }
        }
    }
</script>
<style>
#otp-modal.modal {
    backdrop-filter: blur(2px);
}

#otpInput .otp-input {
    width: 40px;
    height: 50px;
    padding: 5px;
    margin: 0 5px;
    font-size: 1.25rem;
    border-radius: 4px;
    border: 1px solid rgb(0 0 0 / 30%);
    text-align: center;
}

#otpInput .otp-input.error {
    border: 1px solid red !important;
}

#otpInput .otp-input::-webkit-inner-spin-button,
#otpInput .otp-input::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
}
</style>

<style lang="scss" scoped>
.modal-body {
    min-height: 400px !important;
    max-width: 500px !important;
}

.error-container {
    height: 1.3rem;
}

.error-message {
    color: var(--error);
    font-weight: 700;
    font-size: 12px;
}

.header-container {
    background:
        linear-gradient(to right, rgb(0 0 0 / 25%) 0%, rgb(0 0 0 / 25%) 100%),
        linear-gradient(to right, var(--primary-color) 0%, var(--secondary-color) 100%);
    background-size: cover, cover;
    background-position: center;
    background-repeat: no-repeat, no-repeat;
    position: relative;
    min-height: 170px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.footer-container {
    background: var(--white);
    width: 100%;
    min-height: 300px;
    padding: 3rem 2rem 2rem;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 1rem;
    color: var(--black);
}

.avatar-container {
    position: absolute;
    width: 130px;
    top: 100%;
    transform: translateY(-50%);
}

.form-group {
    width: 100%;
    margin-bottom: 5px;
}

.form-group label {
    font-size: 1.3rem;
    color: var(--black);
    font-weight: 700;
    text-align: center;
    display: block;
}

.input-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.otp-clear {
    cursor: pointer;
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
    border-radius: 4px;
    padding: 0.15rem 1rem;
    font-size: 0.9rem;
    margin: 1rem auto 0;
}

.submit-button {
    margin-top: 1rem;
    width: 120px;
    height: 40px;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 1px;
    box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
    border-radius: 4px;
    border: 1px solid transparent;
    background: var(--secondary-color);
    color: var(--white);
    outline: none;
    transition: all 0.2s ease-in-out;
    display: grid;
    place-items: center;
}

input.code {
    max-width: 400px;
    width: 100%;
    margin: 0 auto !important;
}

.logo-center {
    text-align: center;
}

.logo-center img {
    width: 160px;
    height: auto;
}

.otp-button {
    width: 150px;
    padding: auto 0;
}

.close-button {
    position: absolute;
    background: none;
    z-index: 3;
    right: 0;
    outline: none;
    border: none;
}

.close-button svg {
    font-size: 40px;
}

.close-button:hover {
    background: none;
    outline: none;
    border: none;
}

@media screen and (min-width: 768px) {
    .form-group label {
        font-size: 1.5rem;
        margin: 0.5rem auto;
    }
}

</style>

<style lang="scss" >
.modal-content {
    background: transparent !important;
    border: none !important;
}
</style>
