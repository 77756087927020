import Vue from 'vue'
import Router from 'vue-router'

import 'nprogress/nprogress.css'
import NProgress from 'nprogress'
import vueConfig from '@/../vue.config'
import store from '@/store'
import jwtUtils from '@/utils/jwt-utils'

Vue.use(NProgress)
Vue.use(Router)

let router = new Router({
    mode: 'history',
    base: vueConfig.publicPath,
    routes: [
        {
            path: '/welcome',
            name: 'Welcome',
            component: () => import('@/components/routes/WelcomeRoute'),
            meta: {
                title: 'Welcome',
                fullScreen: true,
                notSecure: true
            }
        },
        {
            path: '/free-game',
            name: 'Free Game',
            component: () => import('@/components/routes/FreeGameRoute'),
            meta: {
                title: 'Free Game',
                fullScreen: true,
                notSecure: true
            }
        },
        {
            path: '',
            redirect: 'home',
            meta: {
                title: 'Home',
                fullScreen: true
            }
        },
        {
            path: '/home',
            alias: '/missions',
            name: 'home',
            component: () => import('@/components/routes/HomeRoute'),
            meta: {
                title: 'Home'
            }
        },
        {
            path: '/edit-profile',
            name: 'edit-profile',
            component: () => import('@/components/routes/EditProfileRoute'),
            meta: {
                title: 'Edit Profile'
            }
        },
        {
            path: '/missions/:missionId/tasks/:taskId/edit-profile',
            name: 'edit-profile-as-task',
            component: () => import('@/components/routes/EditProfileRoute'),
            meta: {
                title: 'Edit Profile',
                isTask: true
            }
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: () => import('@/components/routes/ForgotPasswordRoute'),
            meta: {
                title: 'Forgot Password',
                notSecure: true,
                fullScreen: true
            }
        },
        {
            path: '/reset-password/:resetToken',
            name: 'reset-password',
            component: () => import('@/components/routes/ForgotPasswordRoute'),
            meta: {
                title: 'Reset Password',
                notSecure: true,
                fullScreen: true
            }
        },
        {
            path: '/register',
            name: 'Email Register',
            component: () => import('@/components/routes/RegisterRoute'),
            meta: {
                title: 'Register',
                notSecure: true,
                fullScreen: true
            }
        },
        {
            path: '/register/:inviteCode',
            name: 'Invitation Register',
            component: () => import('@/components/routes/RegisterRoute'),
            meta: {
                title: 'Register',
                notSecure: true,
                fullScreen: true
            }
        },
        {
            path: '/token-login/:code',
            name: 'token-login',
            component: () => import('@/components/routes/TokenLoginRoute'),
            meta: {
                title: 'Token Login',
                notSecure: true,
                fullScreen: true
            }
        },
        {
            path: '/barcode/:code',
            name: 'barcode-preview',
            component: () => import('@/components/routes/BarcodeRoute'),
            meta: {
                title: 'Barcode',
                notSecure: true,
                fullScreen: true
            }
        },
        {
            path: '/missions/:missionId',
            name: 'missions',
            component: () => import('@/components/routes/MissionRoute'),
            meta: {
                title: 'Mission'
            }
        },
        {
            path: '/task-summary/:missionId',
            name: 'task-summary',
            component: () => import('@/components/routes/TaskSummaryRoute'),
            meta: {
                title: 'Task Summary'
            }
        },
        {
            path: '/rewards',
            name: 'rewards',
            component: () => import('@/components/routes/RewardsRoute'),
            meta: {
                title: 'Rewards'
            }
        },
        {
            path: '/library',
            name: 'library',
            component: () => import('@/components/routes/LibraryRoute'),
            meta: {
                title: 'Library'
            }
        },
        {
            path: '/missions/:missionId/tasks/:taskId/library',
            name: 'library-as-task',
            component: () => import('@/components/routes/LibraryRoute'),
            meta: {
                title: 'Library',
                isTask: true
            }
        },
        {
            path: '/articles/:articleId',
            name: 'article',
            component: () => import('@/components/routes/ArticleRoute'),
            meta: {
                title: 'Article'
            }
        },
        {
            path: '/surveys',
            name: 'surveys',
            component: () => import('@/components/routes/SurveysRoute'),
            meta: {
                title: 'Surveys'
            }
        },
        {
            path: '/missions/:missionId/tasks/:taskId/surveys/:surveyId',
            name: 'surveys-as-task',
            component: () => import('@/components/routes/SurveyRoute'),
            meta: {
                title: 'Surveys',
                isTask: true
            }
        },
        {
            path: '/surveys/:surveyId',
            component: () => import('@/components/routes/SurveyRoute'),
            name: 'survey',
            meta: {
                title: 'Survey'
            }
        },
        {
            path: '/submit-code',
            name: 'submit-code',
            component: () => import('@/components/routes/SubmitCodeRoute'),
            meta: {
                title: 'Submit Code',
                fullScreen: true
            }
        },
        {
            path: '/leaderboard',
            name: 'leaderboard',
            component: () => import('@/components/routes/LeaderBoardRoute'),
            meta: {
                title: 'LeaderBoard'
            }
        },
        {
            path: '/missions/:missionId/tasks/:taskId',
            name: 'tasks',
            component: () => import('@/components/routes/TaskRoute'),
            meta: {
                title: 'Task',
                fullScreen: true,
                isTask: true
            }
        },
        {
            path: '/missions/:missionId/tasks/:taskId/daily-quiz',
            name: 'daily-quiz',
            component: () => import('@/components/routes/DailyQuizRoute'),
            meta: {
                title: 'Daily Quiz',
                fullScreen: false,
                isTask: true
            }
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/components/routes/LoginRoute'),
            meta: {
                title: 'Login',
                fullScreen: true,
                notSecure: true
            }
        },
        {
            path: '/settings',
            name: 'settings',
            component: () => import('@/components/routes/SettingsRoute'),
            meta: {
                title: 'Settings'
            }
        },
        {
            path: '/incoming',
            name: 'Incoming',
            component: () => import('@/components/routes/IncomingRoute'),
            meta: {
                title: 'Incoming',
                notSecure: true
            }
        },
        {
            path: '/external-leaderboard',
            name: 'External Leaderboard',
            component: () => import('@/components/routes/ExternalLeaderboardRoute'),
            meta: {
                title: 'External Leaderboard',
                notSecure: true,
                fullScreen: true
            }
        },
        {
            path: '/missions/:missionId/tasks/:taskId/challenges',
            name: 'challenges-as-a-task',
            component: () => import('@/components/routes/ChallengesRoute'),
            meta: {
                title: 'Challenges',
                isTask: true
            }
        },
        {
            path: '/challenges',
            name: 'Challenges',
            component: () => import('@/components/routes/ChallengesRoute'),
            meta: {
                title: 'Challenges'
            }
        },
        {
            path: '/challenges/:challengeId',
            name: 'Challenge Game',
            component: () => import('@/components/routes/ChallengeRoute'),
            meta: {
                title: 'Challenge'
            }
        },
        // { RND FOR web scan barcode
        //     path: '/scan-barcode',
        //     name: 'Scan Barcode',
        //     component: () => import('@/components/routes/ScanBarcodeRoute'),
        //     meta: {
        //         title: 'Scan Barcode',
        //         fullScreen: true
        //     }
        // },
        {
            path: '*',
            name: '404 Not Found',
            component: () => import('@/components/routes/NotFoundRoute'),
            meta: {
                title: 'Not Found'
            }
        }
    ]
})

router.beforeResolve((to, from, next) => {
    NProgress.start()
    document.title = 'Gamification Web | ' + to.meta.title
    next()
})

router.beforeResolve((to, from, next) => {
    next()
})

router.beforeEach((to, from, next) => {
    NProgress.start()
    if (to.query.lang === 'en') {
        store.commit('setLanguage', 'EN')
    } else if (to.query.lang === 'el') {
        store.commit('setLanguage', 'EL')
    }
    if (to.query.previewMode === 'true') {
        store.commit('setPreviewMode', true)
    } else if (to.query.previewMode === 'false') {
        store.commit('setPreviewMode', false)
    }
    if (to.query.cheatMode === 'true') {
        store.commit('setCheatMode', true)
    } else if (to.query.cheatMode === 'false') {
        store.commit('setCheatMode', false)
    }

    const apiToken = localStorage.getItem(`${process.env.VUE_APP_API_HOST_DOMAIN}-web`)
    if (!apiToken && to.meta.notSecure) {
        next()
    } else if ((!apiToken || !jwtUtils.isValid(apiToken)) && !(to.path === '/login' || to.path === '/forgot-password' || to.path === '/reset-password')) {
        store.dispatch('logout')
        next({ path: '/welcome', replace: true })
    } else if ((to.path === '/login' || to.path === '/register' || to.path === '/forgot-password' || to.path === '/welcome' || to.path.startsWith('/reset-password')) && apiToken && jwtUtils.isValid(apiToken)) {
        store.dispatch('logout')
        next()
    } else {
        next()
    }
})

router.afterEach(() => {
    NProgress.done()
})

export default router
