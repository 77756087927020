<template>
    <b-modal
        id="logout-modal"
        ref="logout-modal"
        class="modal-content"
        :hide-header="true"
        :hide-footer="true"
        centered
    >
        <div class="header-container">
            <b-button class="close-button" @click="hideModal"><font-awesome-icon :icon="[ 'far', 'times-circle' ]" /></b-button>

            <b-row class="justify-content-center">
                <b-col class="avatar-container">
                    <img src="https://s3.eu-west-1.amazonaws.com/dev-games.mpassltd.com/img/badges/af96bf38-cd8b-45d1-bad9-691fcbeda200-logout-icon.png" alt="logout icon">
                </b-col>
            </b-row>
        </div>

        <div class="footer-container">
            <div>
                <div class="body-head">
                    {{ $t('logoutModal.noLeave') }}
                </div>
                <div v-if="hasWon" class="body-text">
                    {{ $t('logoutModal.successMessage') }}
                </div>
                <div v-if="pointsLeft > 0" class="body-text">
                    {{ $t('logoutModal.pointsLeft', {points: pointsLeft}) }}
                </div>
                <div class="buttons-container">
                    <div
                        v-if="hasWon"
                        class="custom-btn ok"
                        @click="goToRewards"
                    >
                        {{ $t('logoutModal.goToRewards') }}
                    </div>
                    <div
                        v-else
                        class="custom-btn ok"
                        @click="hideModal"
                    >
                        {{ $t('logoutModal.playMore') }}
                    </div>
                    <div
                        class="custom-btn cancel"
                        @click="logout"
                    >
                        {{ $t('logoutModal.exit') }}
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
    import emitter from '@/utils/emitter'
    import couponMixin from '@/mixins/coupon-mixin'
    export default {
        mixins: [couponMixin],
        computed: {
            player() {
                return this.$store.getters.getCurrentUser
            },
            isRewardsEnabled() {
                return this.$store.getters.isRewardsEnabled
            },
            playerAvailablePoints() {
                return this.player?.totalAvailablePoints
            },
            couponNeededPoints() {
                return this.$store.getters.getCouponNeededPoints
            },
            hasWon() {
                return this.isRewardsEnabled && (this.playerAvailablePoints >= this.couponNeededPoints)
            },
            pointsLeft() {
                return (this.couponNeededPoints - this.player?.totalAvailablePoints) || 0
            }
        },
        created() {
            emitter.on('openLogoutModal', () => {
                this.$bvModal.show('logout-modal')
            })
        },
        methods: {
            hideModal() {
                this.$refs['logout-modal'].hide()
            },
            logout() {
                window.location.reload()
                this.hideModal()
                this.$store.dispatch('logout')
            },
            goToRewards() {
                this.hideModal()
                this.$router.push({ name: 'rewards' })
            }
        }
    }
</script>
<style>
#logout-modal.modal {
    backdrop-filter: blur(2px);
}
</style>
<style lang="scss" scoped>
.modal-body {
    min-height: 400px !important;
    max-width: 500px !important;
}

.header-container {
    background:
        url("https://s3.eu-west-1.amazonaws.com/dev-games.mpassltd.com/img/badges/c9b2c2b6-15b0-4b2e-9ced-c88efbd00a46-logout-header.png"),
        linear-gradient(to right, #1b8fbd 0%, #24c1ff 100%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    min-height: 170px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.footer-container {
    background: var(--white);
    width: 100%;
    min-height: 300px;
    padding: 4.5rem 0 0;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.avatar-container {
    position: absolute;
    width: 200px;
    top: 100%;
    left: 46%;
    transform: translate(-50%, -50%);
}

.avatar-container img {
    width: 100%;
    height: auto;
}

.close-button {
    position: absolute;
    background: none;
    z-index: 3;
    right: 0;
    outline: none;
    border: none;
}

.close-button svg {
    font-size: 40px;
}

.close-button:hover {
    background: none;
    outline: none;
    border: none;
}

.body-head {
    font-weight: 700;
    font-size: 1.25rem;
    color: #1b8fbd;
    text-align: center;
    margin: 0 auto;
}

.body-text {
    font-weight: 400;
    font-size: 1rem;
    color: #1b8fbd;
    text-align: center;
    margin: 0 auto;
    padding: 0.5rem 1rem;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    margin: 2rem auto;
}

.custom-btn {
    width: 100%;
    max-width: 250px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0 0 0.2rem rgb(0 0 0 / 30%));
    border-radius: 10px;
    text-decoration: none;
    cursor: pointer;
}

.ok {
    background: #1b8fbd;
    color: var(--white);
    font-weight: 600;
    font-size: 1.1rem;
    letter-spacing: 1px;
}

.cancel {
    background: transparent;
    color: #1b8fbd;
}

</style>

<style lang="scss" >
.modal-content {
    background: transparent !important;
    border: none !important;
}
</style>
