const getters = {
    isUserLoggedIn: state => state.player.isLoggedIn,
    getCurrentUser: state => state.player.player,
    getCouponModalShown: state => state.player.couponModalshown,
    getLanguage: state => state.app.language,
    deviceType: state => state.app.deviceType,
    getAvailableLanguages: state => state.app.availableLanguages,
    isPreviewMode: state => state.app.previewMode,
    isCheatMode: state => state.app.cheatMode,
    isTest: state => state.app.test,
    getCouponNeededPoints: state => state.app.couponNeededPoints,
    getRanks: state => state.player.ranks,
    getTotalPlayers: state => state.player.totalPlayers,
    getMissions: state => state.mission.missions,
    getTotalMissionCount: state => state.mission?.missions?.length,
    getMissionsLoading: state => state.mission.missionsLoading,
    getMission: (state) => (missionId) => state.mission.missions.find(m => m.id === missionId),
    getSecondsLeft: (state) => (taskId) => {
        if (!state.app.timers[taskId]) {
            return 0
        }
        return state.app.timers[taskId].secondsLeft
    },
    getChallenge: (state) => (challengeId) => {
        return state.challenges.availableChallenges.find(c => c.id === challengeId)
    },
    getTask: (state) => (missionId, taskId) => {
        const mission = state.mission.missions.find(m => m.id === missionId)
        if (!mission) {
            return {}
        }
        return mission.tasks.find(t => t.id === taskId)
    },
    getTaskIndex: (state) => (missionId, taskId) => {
        const mission = state.mission.missions.find(m => m.id === missionId)
        if (!mission) {
            return -1
        }
        return mission.tasks.findIndex(t => t.id === taskId)
    },
    getNextTaskPath: (state, getters) => (missionId) => {
        missionId = parseInt(missionId)
        const mission = state.mission.missions.find(m => m.id === missionId)
        if (!mission) {
            return ''
        }
        const nextTask = mission.tasks.find(task => task.next)

        if (nextTask?.taskType?.alias === 'ACTION_PROFILE') {
            return `/missions/${missionId}/tasks/${nextTask.id}/edit-profile`
        } else if (nextTask?.taskType?.alias === 'ACTION_LIBRARY') {
            return `/missions/${missionId}/tasks/${nextTask.id}/library`
        } else if (nextTask?.taskType?.alias === 'ACTION_SURVEY') {
            const nextTaskSurvey = nextTask.surveys[0]
            return `/missions/${missionId}/tasks/${nextTask.id}/surveys/${nextTaskSurvey.id}`
        } else if (nextTask?.taskType?.alias === 'QUIZ_DAILY') {
            return `/missions/${missionId}/tasks/${nextTask.id}/daily-quiz`
        } else if (nextTask?.taskType?.alias === 'ACTION_ARENA') {
            return `/missions/${missionId}/tasks/${nextTask.id}/challenges`
        } else if (mission.tasks.length === 1 && getters.skipMissionRouteOnSingleTask) {
            return `/missions/${missionId}/tasks/${mission.tasks[0].id}`
        } else if (nextTask) {
            return `/missions/${missionId}/tasks/${nextTask.id}`
        } else {
            return ''
        }
    },
    getTaskPath: (state) => (missionId, taskId) => {
        missionId = parseInt(missionId)
        taskId = parseInt(taskId)
        const mission = state.mission.missions.find(m => m.id === missionId)
        if (!mission) {
            return
        }
        const task = mission.tasks.find(t => t.id === taskId)
        if (!task) {
            return
        }
        if (task.taskType.alias === 'ACTION_PROFILE') {
            return `/missions/${missionId}/tasks/${task.id}/edit-profile`
        } else if (task.taskType.alias === 'ACTION_LIBRARY') {
            return `/missions/${missionId}/tasks/${task.id}/library`
        } else if (task.taskType.alias === 'ACTION_SURVEY') {
            const nextTaskSurvey = task.surveys[0]
            return `/missions/${missionId}/tasks/${task.id}/surveys/${nextTaskSurvey.id}`
        } else if (task.taskType.alias === 'QUIZ_DAILY') {
            return `/missions/${missionId}/tasks/${task.id}/daily-quiz`
        } else if (task.taskType.alias === 'ACTION_ARENA') {
            return `/missions/${missionId}/tasks/${task.id}/challenges`
        } else {
            return `/missions/${missionId}/tasks/${task.id}`
        }
    },
    isTaskCompleted: (state) => (missionId, taskId) => {
        const mission = state.mission.missions.find(m => m.id === missionId)
        if (!mission) {
            return {}
        }
        return mission.tasks.find(t => t.id === taskId)?.completed
    },
    getConfigFeatures: state => state.app?.config?.features,
    getHomeBanners: state => {
        return state.app.commercialBanners.filter(b => b.position === 'HOME')
    },
    getConfig: state => {
        return state.app?.config
    },
    getTheming: state => state.app?.config?.theming,
    getWelcomeTheme: state => state.app?.config.theming.welcomeTheme,
    getBranding: state => state.app?.config?.branding,
    getLeaderboardConfig: state => state.app?.config?.leaderboards,
    mainBackgroundExists: state => !!state.app?.config?.theming?.backgroundImageUrl,
    getInCodeLabels: state => state.app?.config?.inCodeLabels,
    getPlayerProfileFields: state => state.app?.config?.playerProfileFields,
    getPlayerProfileDropdownFields: state => state.app?.config?.playerProfileDropdownFields,
    getPointsToRedeem: state => state.rewards.pointsToRedeem,
    getErrorMessage: state => state.rewards.errorMessage,
    isSendingRewards: state => state.rewards.isSendingRewards,
    isPlayAgainEnabled: state => state.app?.config?.features?.playAgainEnabled,
    isLeaderboardEnabled: state => state.app?.config?.features?.leaderboardEnabled,
    isBadgesEnabled: state => state.app?.config?.features?.badgesEnabled,
    isRewardsEnabled: state => state.app?.config?.features?.rewardsEnabled,
    areChallengesEnabled: state => state.app?.config?.features?.challengesEnabled || false,
    isMissionProgressbarEnabled: state => state.app?.config?.features?.missionProgressbarEnabled,
    isSidebarProgressInfoEnabled: state => state.app?.config?.features?.sidebarProgressInfoEnabled,
    skipMissionRouteOnSingleTask: state => state.app?.config?.features?.skipMissionRouteOnSingleTask,
    getRewardCart: state => state.rewards.rewardCart,
    getRewardCartTotalPoints: state => {
        const tempPoints = state.rewards.rewardCart.map(r => r.rewardLevel.points * r.amount)
        return tempPoints.reduce((total, points) => total + points, 0)
    },
    getScanCode: state => state.app.sc,
    getFreeTaskInfo: state => state.freeTask.freeTaskInfo,
    getAccessCodeLength: state => parseInt(state.app?.config?.accessCode?.length),
    getPreferredLanguages: state => state.app?.config?.branding?.preferredLanguages,
    availableChallenges: state => state.challenges.availableChallenges,
    getChallengeSessionId: state => state.challengeSessions.challengeSessionId,
    getPendingChallengeSessions: state => state.challengeSessions.pendingChallengeSessions,
    getCompletedChallengeSessions: state => state.challengeSessions.completedChallengeSessions,
    getRemainingChallengeSessions: state => state.challengeSessions.remainingChallengeSessions,
    getExpiredChallengeSessions: state => state.challengeSessions.expiredChallengeSessions,
    getTimeUntilAvailableSessions: state => state.challengeSessions.timeUntilAvailableSessions,
    getNextAvailableSessionDate: state => state.challengeSessions.nextAvailableChallengeSessionDate,
    getCreateChallengeSessionData: state => state.challengeSessions.createChallengeSessionData,
    getChallengeId: state => state.challengeSessions.challengeId,
    getJoinerChallengerId: state => state.challengeSessions.joinerChallengerId
}

export default getters
